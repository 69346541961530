import './App.css';
// import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services';
import Footer from './components/Footer';
import Designs from './components/Designs';
import Contact from './components/Contact';


function App() {
  return (
    <>
    <Navbar/>
    <Home/>
    <Services/>
    <Designs/>
    <Contact/>
    <Footer/>
    </>
  );
}

export default App;
