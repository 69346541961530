import React from 'react'
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiSnapchatLine } from "react-icons/ri";
import { AiOutlineYoutube } from "react-icons/ai";

const Contact = () => {
    return (
        <>
            <hr className='main-hr' />
            <section className="contact">
                <div className="heading-text">
                    <h2>Contact us</h2>
                </div>
                <di className="contact-box">
                    <div className="left">
                        <h2>Get in touch...</h2>
                        <p>Get in touch with us. If you have any problem or querry then massage us !</p>
                        <div className="info">
                            <AiOutlineMail className="media-icon" />
                            <div className="info-spans">
                                <span className='one'>Email</span>
                                <span className='two'>tusharmore2143@gmail.com</span>
                            </div>
                        </div>
                        <div className="info">
                            <AiOutlineInstagram className="media-icon" />
                            <div className="info-spans">
                                <span className='one'>Instagram</span>
                                <span className='two'>tushu_more</span>
                            </div>
                        </div>
                        <div className="info">
                            <RiSnapchatLine className="media-icon" />
                            <div className="info-spans">
                                <span className='one'>Snapchat</span>
                                <span className='two'>Tushar</span>
                            </div>
                        </div>
                        <div className="info">
                            <AiOutlineYoutube className="media-icon" />
                            <div className="info-spans">
                                <span className='one'>YouTube</span>
                                <span className='two'>TSM Design</span>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <h2>Massage Us !</h2>
                        <div className="two-inputs">
                            <input type="text" placeholder='Name' />
                            <input type="text" placeholder='Email' />
                        </div>
                        <input type="text" placeholder='Subject'/>
                        <textarea placeholder='Massage'></textarea>
                        <button className='btn'>Send</button>
                    </div>
                </di>
            </section>
        </>
    )
}

export default Contact
