import React, { useState } from 'react'
import  './Style.css'
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineBars } from "react-icons/ai";
// import { Link } from 'react-router-dom';

const Navbar = () => {

  const [showMenuIcon, setShowMenuIcon] = useState(false);
  return (
    <>
    <div className='navbar'>

      {/* logo section  */}
        <div className='logo'>
            <h3>TSM<span>Design</span></h3>
            <p>The desings that you loved <AiFillHeart className='logo_icon'/></p>
        </div>

        {/* navlist section  */}
        <div className='navlist'>
          <ul className={showMenuIcon ? 'nav-links mobile-nav' : 'nav-links'}>
            <li className='link' to={"/"}>Home</li>
            <li className='link' to={"/docs"}>Services</li>
            <li className='link' to={"/"}>Designs</li>
            <li className='link' to={"/"}>Contact us</li>
          <button className='btn'>Log In</button>
          </ul>
          <div className='hamburger'>
              <span href='#' onClick={()=> setShowMenuIcon(!showMenuIcon)}>
                <AiOutlineBars className='hamburger_icon'/>
                </span>
          </div>
        </div>
    </div>
    </>
  )
}

export default Navbar
