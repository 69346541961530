import React from 'react'
import { IoColorPalette } from "react-icons/io5";
import { FaLaptopCode } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";

const Docs = () => {
  return (
    <>
    <hr className='main-hr'/>
    <section className="services">
      <div className="heading-text">
        <h2>Services</h2>
      </div>
      <div className="service-boxes">
        <div className="service-box">
          <IoColorPalette className='service-icon'/>
          <h2 className="service-heading">Graphic Designers!</h2>
          <hr className='secondary-hr'/>
          <p className="service-para">All templates were designed by our specilist graphic designers. And you loved them!</p>
        </div>
        <div className="service-box">
          <FaLaptopCode className='service-icon'/>
          <h2 className="service-heading">Skilled Developers!</h2>
          <hr className='secondary-hr'/>
          <p className="service-para">Our all web-appliations are developed by our most skillfull Developers that make your web-application more attractive!</p>
        </div>
        <div className="service-box">
          <MdOutlineSecurity className='service-icon'/>
          <h2 className="service-heading">Security Accurence!</h2>
          <hr className='secondary-hr'/>
          <p className="service-para">We provide best security to to our all projects. Our customers security is most important!</p>
        </div>
      </div>
    </section>
    </>
  )
}

export default Docs