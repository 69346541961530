import React from 'react'

const Footer = () => {
  return (
    <>
    <hr  className='main-hr'/>
    <div className="footer">
    <p>Copyright &copy; TSM<span>Design</span> 2022-23</p>
    </div>
    </>
  )
}

export default Footer
