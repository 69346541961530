import React from 'react'
import design1 from '../images/design1.png'
import design2 from '../images/design2.png'
import design3 from '../images/design3.png'

const Designs = () => {
    return (
        <>
        <hr className='main-hr'/>
            <section className="designs">
                <div className="heading-text">
                    <h2>Our Designs</h2>
                </div>

                <div className="design-boxes">
                    <div className="design-box">
                        <img src={design1} alt="design1" />
                    </div>
                    <div className="design-box">
                        <img src={design2} alt="design2" />
                    </div>
                    <div className="design-box">
                        <img src={design3} alt="design3" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Designs
