import React from 'react'
import './Style.css'
import Hero from '../images/hm-team.png'

const Home = () => {
  return (
    <>
    <div className='container'>
          <div className='left'>
            <h1>Create Something Big here. with Us!</h1>
            <p>Create something valuble for you
               and others that people will connect
               with you.</p>
              <button className='btn2'>Create Now!</button>
          </div>
          <div className='Right'>
            <img src={Hero} alt="Hero" />
          </div>
    </div>
    </>
  )
}

export default Home
